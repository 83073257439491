import React from 'react';
import Detail from './Detail';
import Detail1 from '../img/detail1.png';
import Detail2 from '../img/detail2.png';
import Detail3 from '../img/detail3.png';
import { Row, Col } from 'react-bootstrap';

const Details = () => {
  return (
    <div className="grid container">
      <Row>
        <Col sm={12} md={4}>
          <Detail
            title="Front End Dev"
            description="React solutions using HTML, SCSS, Javascript/Typescript"
            back={Detail1}
          />
        </Col>
        <Col sm={12} md={4}>
          <Detail
            title="Back End Dev"
            description="Node, Express, MongoDB and GraphQL"
            back={Detail2}
          />
        </Col>
        <Col sm={12} md={4}>
          <Detail
            title="UX/UI Design"
            description="Clean and modern User Experience/User Interface designs"
            back={Detail3}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Details;
