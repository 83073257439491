import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <h1>  react.net.au</h1>
    </div>
  );
};

export default Footer;
