import React from 'react';

const Header = () => {
  return (
    <div className="header">
      <p id="domain">react.net.au</p>
    </div>
  );
};

export default Header;
