import React from 'react';
import { Col } from 'react-bootstrap';

const Display = () => {
  return (
    <Col>
      <div className="display">
        <h1 className="display-title display-1">ReactJS Solutions</h1>
        <p className="display-sub">Mobile and Web Apps</p>
      </div>
    </Col>
  );
};

export default Display;
