import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import RowBtn from './RowBtn';
const ButtonRow = ({ screen, onButtonClick }) => {
  const [folioSelected, setFolioSelected] = useState(true);
  const [clientSelected, setCliendSelected] = useState(false);
  const [educationSelected, setEducationSelected] = useState(false);

  useEffect(() => {
    if (screen === 'folio') {
      setFolioSelected(true);
      setCliendSelected(false);
      setEducationSelected(false);
    } else if (screen === 'clients') {
      setFolioSelected(false);
      setCliendSelected(true);
      setEducationSelected(false);
    } else if (screen === 'education') {
      setFolioSelected(false);
      setCliendSelected(false);
      setEducationSelected(true);
    }
  }, [screen]);

  return (
    <div className="btn-grid">
      <Row>
        <Col xs={4}>
          <RowBtn
            title="Work"
            onButtonClick={() => onButtonClick('folio')}
            active={folioSelected}
          />
        </Col>
        <Col xs={4}>
          <RowBtn
            title="Clients"
            onButtonClick={() => onButtonClick('clients')}
            active={clientSelected}
          />
        </Col>
        <Col xs={4}>
          <RowBtn
            title="Edu"
            onButtonClick={() => onButtonClick('education')}
            active={educationSelected}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ButtonRow;
